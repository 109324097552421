<template>
  <div>
    <h2 class="headline--md text-bold text-uppercase">Bought gold order detail</h2>

    <div class="box box--md box--white mt-16">
      <table class="table table--list three-items">
        <tbody>
          <tr>
            <td>
              <div class="statistic">
                <p class="statistic__title text-warm">Order No.</p>
                <h2 class="headline--md mt-4">123456790</h2>
              </div>
            </td>
            <td>
              <div class="statistic">
                <p class="statistic__title text-warm">Payment No.</p>
                <h2 class="headline--md mt-4">123456790</h2>
              </div>
            </td>
            <td>
              <div class="statistic">
                <p class="statistic__title text-warm">Status</p>
                <h2 class="headline--md mt-4">PENDING</h2>
              </div>
            </td>
          </tr>

          <tr>
            <td>
              <div class="statistic">
                <p class="statistic__title text-warm">Buying Gold Amount (gram)</p>
                <h2 class="headline--md mt-4">0.02</h2>
              </div>
            </td>
            <td>
              <div class="statistic">
                <p class="statistic__title text-warm">Buying Amount (USDT)</p>
                <h2 class="headline--md mt-4">{{ 30000 | number_with_commas }}</h2>
              </div>
            </td>
            <td>
              <div class="statistic">
                <p class="statistic__title text-warm">Markup Fee Reduction</p>
                <h2 class="headline--md mt-4">YES</h2>
              </div>
            </td>
          </tr>

          <tr>
            <td>
              <div class="statistic">
                <p class="statistic__title text-warm">Gas Fee</p>
                <h2 class="headline--md mt-4">0.5 (Super Fast)</h2>
              </div>
            </td>
            <td />
            <td />
          </tr>
        </tbody>
      </table>
    </div>

    <h2 class="headline--md text-bold text-uppercase mt-32">Account information</h2>
    <div class="box box--md box--white mt-16">
      <table class="table table--list three-items">
        <tbody>
          <tr>
            <td>
              <div class="statistic">
                <p class="statistic__title text-warm">Email</p>
                <h2 class="headline--md mt-4">dinhhaildh9@gmail.com</h2>
              </div>
            </td>
            <td>
              <div class="statistic">
                <p class="statistic__title text-warm">Full Name</p>
                <h2 class="headline--md mt-4">dinhhaildh9</h2>
              </div>
            </td>
            <td>
              <div class="statistic">
                <p class="statistic__title text-warm">Phone</p>
                <h2 class="headline--md mt-4">+62 1223456789</h2>
              </div>
            </td>
          </tr>

          <tr>
            <td>
              <div class="statistic">
                <p class="statistic__title text-warm">Blacklist</p>
                <h2 class="headline--md mt-4 text-error text-bold">{{ 1 | format_blacklist_type }}</h2>
              </div>
            </td>
            <td />
            <td />
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {}
</script>
